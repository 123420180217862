/* eslint-disable @typescript-eslint/consistent-type-assertions */
import axios, { AxiosError, AxiosInstance, AxiosRequestHeaders, AxiosResponse } from 'axios';

import Api from '@services/index';
import { getStorageToken } from '@utils/storageUser';
import { IBaseResponse } from './interfaces';

export const instanceOptions = {
  baseURL: process.env.REACT_APP_SERVICE_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json;charset=utf-8',
  },
  withCredentials: true,
};

const axiosInstance: AxiosInstance = axios.create(instanceOptions);

axiosInstance.interceptors.request.use(
  async (options) => {
    let { token, tokenExpires } = getStorageToken();

    if (!token) {
      return options;
    }

    if (Date.now() > tokenExpires) {
      token = await Api.user.refreshToken();
    }

    const customHeader = {
      ...options.headers,
      Authorization: `Bearer ${token}`,
    } as AxiosRequestHeaders;

    return {
      ...options,
      headers: customHeader,
    };
  },
  async (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response: AxiosResponse<IBaseResponse<Record<string, unknown>>>) => response,
  async (error: AxiosError<IBaseResponse<Record<string, unknown>>, { _retry?: boolean }>) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
