import React, { useState } from 'react';
import { Button, Flex, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useBoundAnswersStore } from '@store/slices/answers';

import AnswersModal from '../../Answers/AnswersModal';
import styles from './styles.module.less';

const { Text } = Typography;

const Bottom = () => {
  const [openViewModal, setOpenViewModal] = useState(false);
  const { maps, date } = useBoundAnswersStore(({ maps, date }) => ({ maps, date }));
  const navigate = useNavigate();
  const hasDay = maps.has(date.format('DD-MM-YYYY'));

  const handleClick = () => {
    if (hasDay) {
      setOpenViewModal(true);
      return;
    }

    navigate('polls', { state: { day: date.toISOString() } });
  };

  const onCloseModal = () => {
    setOpenViewModal(false);
  };

  return (
    <>
      {openViewModal && <AnswersModal isOpen={openViewModal} closeModal={onCloseModal} />}
      <Flex className={styles.bottom} justify="center" vertical align="center" gap={12}>
        {hasDay && (
          <Text strong className={styles.txt}>
            Вы сегодня уже заполнили тест 💚
          </Text>
        )}
        <Button type="primary" size="large" onClick={handleClick} className={styles.btn}>
          {hasDay ? 'Просмотр' : 'Начать тест'}
        </Button>
      </Flex>
    </>
  );
};

export default Bottom;
