import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Spin, Layout, Grid } from 'antd';

import { useBoundUserStore } from '@store/slices/user';
import Top from './Top';
import styles from './styles.module.less';
import { useBoundAnswersStore } from '@store/slices/answers';
import { useBoundPollsStore } from '@store/slices';
import classNames from 'classnames';

const { Content } = Layout;
const { useBreakpoint } = Grid;

const PatientLayout = (): React.ReactElement => {
  const navigate = useNavigate();
  const { md } = useBreakpoint();
  const [loading, setLoading] = useState(true);
  const { getUserMe } = useBoundUserStore((state) => state);
  const { getAnswers } = useBoundAnswersStore(({ getAnswers }) => ({ getAnswers }));
  const { getPollsList } = useBoundPollsStore(({ getPollsList }) => ({ getPollsList }));

  useEffect(() => {
    getUserMe()
      .then(() => {
        setLoading(false);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        getAnswers();
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        getPollsList();
      })
      .catch(() => {
        navigate('/auth/login');
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '400px',
        }}
      >
        <Spin />
      </div>
    );
  }

  return (
    <div className={styles.patient}>
      <Top />

      <div className={classNames(styles.container, { _mobile: !md })}>
        <Content>
          <Outlet />
        </Content>
      </div>
    </div>
  );
};

export default PatientLayout;
