import axiosInstance from '@services/axios';
import { IAnswerRequest, IUpdateAnswerRequest, TAnswerResponse, TAnswersResponse } from './interfaces';

class AnswersService {
  async saveAnswers(params: IAnswerRequest): Promise<TAnswerResponse> {
    const response = await axiosInstance.post<TAnswerResponse>('/answers/patient', params);

    return response.data;
  }

  async updateAnswers({ fields, id }: IUpdateAnswerRequest): Promise<TAnswerResponse> {
    const response = await axiosInstance.patch<TAnswerResponse>(`/answers/patient/${id}`, fields);

    return response.data;
  }

  async getAnswers(): Promise<TAnswersResponse> {
    const response = await axiosInstance.get<TAnswersResponse>('/answers/patient');

    return response.data;
  }

  async getAnswerById(id: string): Promise<TAnswerResponse> {
    const response = await axiosInstance.get<TAnswerResponse>(`/answers/patient/${id}`);

    return response.data;
  }
}

export const Answers = new AnswersService();
