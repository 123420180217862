import { createBrowserRouter } from 'react-router-dom';

import PatientLayout from '@components/layouts/Patient';
import AuthLayout from '@components/layouts/Auth';
import NotFound from '@components/NotFound';

import SignIn from './Auth/SignIn';
import Forgot from './Auth/Forgot';
import ResetPassword from './Auth/Reset';

import Dashboard from './main/Dashboard';
import Polls from './main/Polls';
import Profile from './main/Profile';
import Answers from './main/Answers';

export const router = createBrowserRouter([
  {
    path: 'auth',
    element: <AuthLayout />,
    children: [
      {
        index: true,
        path: 'login',
        element: <SignIn />,
      },
      {
        path: 'forgot',
        element: <Forgot />,
      },
      {
        path: 'reset',
        element: <ResetPassword />,
      },
    ],
  },
  {
    path: '',
    element: <PatientLayout />,
    children: [
      {
        index: true,
        element: <Dashboard />,
      },
      {
        path: 'polls',
        element: <Polls />,
      },
      {
        path: 'profile',
        element: <Profile />,
      },
    ],
  },
  {
    path: '*',
    element: <NotFound />,
  },
]);
