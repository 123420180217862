import React, { useState } from 'react';
import { Button, Flex, Form, Input, Typography } from 'antd';
import { Link, useNavigate } from 'react-router-dom';

import { IFields, ILoginParams } from './interfaces';
import { useBoundUserStore } from '@store/slices';

const { Title } = Typography;

const SignIn = () => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const { login } = useBoundUserStore(({ login }) => ({ login }));
  const [form] = Form.useForm<{ email: string; password: string }>();

  const onSubmit = (values: ILoginParams) => {
    setLoading(true);

    login(values)
      .then(() => {
        navigate('/');
        setLoading(false);
      })
      .catch(() => {
        form.setFields([{ name: 'email', errors: ['неправильно введет емейл или пароль'] }]);
        setLoading(false);
      });
  };

  return (
    <Flex gap="middle" vertical>
      <Title level={2} style={{ textAlign: 'center', marginBottom: '24px' }}>
        Авторизоваться
      </Title>

      <Form
        name="basic"
        initialValues={{ email: '', password: '' }}
        onFinish={onSubmit}
        autoComplete="off"
        form={form}
        layout="vertical"
      >
        <Form.Item<ILoginParams>
          label="E-mail"
          name="email"
          required={false}
          rules={[
            {
              type: 'email',
              message: 'The input is not valid E-mail!',
            },
            {
              required: true,
              message: 'Please input your E-mail!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item<ILoginParams>
          label="Пароль"
          name="password"
          required={false}
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input.Password />
        </Form.Item>
        <Flex justify="end" style={{ marginTop: '12px', marginBottom: '24px' }}>
          <div>
            <Link to="/auth/forgot">Забыли Пароль?</Link>
          </div>
        </Flex>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isLoading} style={{ width: '100%' }} size="large">
            Войти
          </Button>
        </Form.Item>
      </Form>
    </Flex>
  );
};

export default SignIn;
