import React, { ReactElement, useCallback } from 'react';

import { IPollItem } from '@services/Polls/interfaces';
import FormBuilder from '@components/UI/FormBuilder';

import { IProps } from './interfaces';
import styles from './styles.module.less';
import { Flex, Typography } from 'antd';
import { useBoundPollsStore } from '@store/slices';

const FormElement = ({ onChange, current: { title, children } }: IProps): ReactElement => {
  const { answers } = useBoundPollsStore(({ answers }) => ({
    answers,
  }));

  const renderInputs = useCallback(
    (item: IPollItem): ReactElement => (
      // @ts-ignore
      <FormBuilder
        {...item}
        onChange={onChange}
        key={item.key}
        fieldKey={item.key}
        defaultValue={answers[item.key]}
        defaultValueAbout={item.about?.key ? String(answers[item.about?.key]) : undefined}
      />
    ),
    [children]
  );

  return (
    <div className={styles.form}>
      <div className={styles.title}>
        <Typography.Title level={4}>{title}</Typography.Title>
      </div>

      <Flex vertical={true} gap={12}>
        {children.map(renderInputs)}
      </Flex>
    </div>
  );
};

export default FormElement;
