import React from 'react';
import { Link } from 'react-router-dom';
import { Layout, Dropdown, Flex, Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { MenuProps } from 'antd/lib';
import { useBoundUserStore } from '@store/slices';
import { ReactComponent as Logo } from '@assets/logo.svg';

const { Header } = Layout;

const headerStyle: React.CSSProperties = {
  height: 'inherit',
  paddingInline: 14,
  paddingTop: 34,
  paddingBottom: 34,
  lineHeight: 1,
  backgroundColor: 'transparent',
};

const Top = () => {
  const { logOut } = useBoundUserStore(({ logOut }) => ({ logOut }));

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: <Link to="/profile">Профиль</Link>,
      icon: <UserOutlined />,
    },
    {
      type: 'divider',
    },
    {
      key: '3',
      onClick: logOut,
      label: <div>Выйти</div>,
      danger: true,
    },
  ];

  return (
    <Header style={headerStyle}>
      <div style={{ maxWidth: '1240px', margin: '0 auto' }}>
        <Flex justify="space-between" align="center">
          <Link to="/">
            <Logo width={160} height={60} />
          </Link>

          <Dropdown menu={{ items }} trigger={['click']} placement="bottomLeft">
            <Avatar icon={<UserOutlined />} style={{ cursor: 'pointer' }} />
          </Dropdown>
        </Flex>
      </div>
    </Header>
  );
};

export default Top;
