/* eslint-disable @typescript-eslint/no-floating-promises */
import { create, StateCreator } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import Api from '@services/index';

import { TSliceUser } from './interfaces';
import {
  IForgotPasswordPayload,
  ILoginRequest,
  IPayloadChaneUserInfo,
  IPaylodChangePassword,
  IResetPasswordPayload,
  IUser,
} from '@services/User/interfaces';
import { removeStorageToken, setStorageToken } from '@utils/storageUser';
import { isAxiosError } from 'axios';
import { router } from '@src/views/routes';
import { IUpdatePatient } from '@services/Patients/interfaces';

const createUserSlice: StateCreator<
  TSliceUser,
  [['zustand/immer', TSliceUser], ['zustand/devtools', never]],
  [],
  TSliceUser
> = (set) => ({
  user: null,
  patient: null,
  isLoading: false,
  error: '',
  changePassword: async (params: IPaylodChangePassword) => {
    try {
      const { data: user } = await Api.user.changePassword(params);

      if (!user) {
        throw new Error('userError');
      }
    } catch (err: unknown) {
      if (isAxiosError<{ errors: Record<string, string> }>(err)) {
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw err.response?.data?.errors;
      }
    }
  },

  updatePatient: async (params: IUpdatePatient) => {
    try {
      const { data } = await Api.patients.updatePatient(params);

      if (!data) {
        throw new Error('userError');
      }

      return set((state) => ({ patient: { ...state.patient, ...params } }));
    } catch (err: unknown) {
      if (isAxiosError<{ errors: Record<string, string> }>(err)) {
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw err.response?.data?.errors;
      }
    }
  },

  changeEmail: async (params: IPayloadChaneUserInfo) => {
    try {
      const { data } = await Api.user.changeUserInfo(params);

      if (!data) {
        throw new Error('userError');
      }

      return set((state) => {
        (state.user as IUser).email = params.email;
      });
    } catch (err: unknown) {
      if (isAxiosError<{ errors: Record<string, string> }>(err)) {
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw err.response?.data?.errors;
      }
    }
  },

  logOut: () => {
    set({ user: null, isLoading: false, error: '', patient: null });
    removeStorageToken();
    router.navigate('/auth/login');
  },
  getUserMe: async () => {
    set({ isLoading: true });

    try {
      const { data: user } = await Api.user.fetchUserMe();
      const { data: patient } = await Api.patients.fetchPatient();

      if (!user || !patient) {
        throw new Error('notFound');
      }

      set({ isLoading: false, user, patient });
    } catch (err: unknown) {
      if (isAxiosError<{ errors: Record<string, string> }>(err)) {
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw err.response?.data?.errors;
      }
    }

    return undefined;
  },
  resetPassword: async (params: IResetPasswordPayload) => {
    try {
      const { data } = await Api.user.resetPassword(params);

      if (!data) {
        throw new Error('notFound');
      }
    } catch (err: unknown) {
      if (isAxiosError<{ errors: Record<string, string> }>(err)) {
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw err.response?.data?.errors;
      }
    }
  },
  forgot: async (params: IForgotPasswordPayload) => {
    try {
      const { data } = await Api.user.forgot(params);

      if (!data) {
        throw new Error('notFound');
      }

      const { user, ...rest } = data;

      set({ isLoading: false, user });
      setStorageToken(rest);
    } catch (err: unknown) {
      if (isAxiosError<{ errors: Record<string, string> }>(err)) {
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw err.response?.data?.errors;
      }
    }
  },
  login: async (params: ILoginRequest) => {
    // set({ isLoading: true });

    try {
      const { data } = await Api.user.login(params);

      if (!data) {
        throw new Error('notFound');
      }

      const { user, ...rest } = data;

      set({ user });
      setStorageToken(rest);
    } catch (err: unknown) {
      if (isAxiosError<{ errors: Record<string, string> }>(err)) {
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw err.response?.data?.errors;
      }
    }

    return undefined;
  },
});

export const useBoundUserStore = create<TSliceUser>()(
  devtools(
    immer((...a) => ({
      ...createUserSlice(...a),
    }))
  )
);

export * from './interfaces';
