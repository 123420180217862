import React, { useState } from 'react';
import { Button, Divider, Flex, Form, Grid, Input, Typography } from 'antd';
import { EditOutlined, SaveOutlined } from '@ant-design/icons';

import { useBoundUserStore } from '@store/slices/user';
import NotFound from '@components/NotFound';
import MaskedInput from '@components/MaskedInput';
import styles from './styles.module.less';
import classNames from 'classnames';

const Account = () => {
  const { md } = Grid.useBreakpoint();
  const [edit, setEdit] = useState(false);
  const { patient, updatePatient } = useBoundUserStore(({ patient, updatePatient }) => ({ patient, updatePatient }));
  const [form] = Form.useForm();

  if (!patient) {
    return <NotFound />;
  }

  const onToggle = () => {
    setEdit(!edit);
  };

  const onSubmit = (values: any) => {
    setEdit(false);
    updatePatient({ ...values, id: patient.id });
  };

  const { firstName, lastName, middleName, phone, telegram } = patient;

  return (
    <div>
      <Flex justify="space-between">
        <Typography.Title level={3}>Персональные данные</Typography.Title>
      </Flex>

      <Divider style={{ margin: '24px 0' }} />

      <Form
        name="account"
        initialValues={{ firstName, lastName, middleName, phone, telegram }}
        onFinish={onSubmit}
        autoComplete="off"
        layout="vertical"
        disabled={!edit}
        form={form}
      >
        <div className={classNames(styles.grid, { _mobile: !md })}>
          <Form.Item name="firstName" label="Имя">
            <Input />
          </Form.Item>

          <Form.Item name="lastName" label="Фамилия">
            <Input />
          </Form.Item>

          <Form.Item name="middleName" label="Отчество">
            <Input />
          </Form.Item>
        </div>

        <div className={classNames(styles.grid, { _mobile: !md })}>
          <Form.Item label="Телефон" name="phone">
            <MaskedInput mask={'+0 (000) 000-00-00'} readOnly={!edit} disabled={!edit} />
          </Form.Item>

          <Form.Item label="Telegram" name="telegram">
            <Input prefix="@" />
          </Form.Item>
        </div>
      </Form>

      <Divider style={{ margin: '24px 0' }} />

      <Flex justify="end" gap={24}>
        {edit ? (
          <>
            <Button
              type="primary"
              danger
              onClick={() => {
                form.resetFields();
                setEdit(false);
              }}
            >
              Отменить
            </Button>
            <Button type="primary" icon={<SaveOutlined />} disabled={!edit} onClick={() => form.submit()}>
              Сохранить изменения
            </Button>
          </>
        ) : (
          <Button onClick={onToggle} icon={<EditOutlined />} htmlType="button">
            Редактировать
          </Button>
        )}
      </Flex>
    </div>
  );
};

export default Account;
