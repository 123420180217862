export interface IErrorResponse {
  code: string;
  displayMessage: string;
  id: string;
  message: string;
  systemId: string;
}

export interface IBaseResponse<T> {
  actualTimestamp: number;
  data: T;
  status: string;
  error?: IErrorResponse;
}

export enum ELoadingStatus {
  Ok = 'ok',
  Pending = 'pending',
  Fail = 'fail',
}

export enum StatusEnum {
  'active' = 1,
  'inactive' = 2,
  'invated' = 3,
  'draft' = 4,
}

export type TPrimitive = boolean | number | string;
