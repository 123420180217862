import { create, StateCreator } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import Api from '@services/index';

import { TSliceViewAnswer } from './interfaces';
import { ELoadingStatus } from '@src/interfaces/common';

const createViewAnswerSlice: StateCreator<
  TSliceViewAnswer,
  [['zustand/immer', TSliceViewAnswer], ['zustand/devtools', never]],
  [],
  TSliceViewAnswer
> = (set) => ({
  item: null,
  loading: ELoadingStatus.Pending,
  error: '',
  getAnswer: async (id: string) => {
    set({ loading: ELoadingStatus.Pending });

    try {
      const { data } = await Api.answers.getAnswerById(id);

      if (!data) {
        throw new Error('error');
      }

      set({ item: data, loading: ELoadingStatus.Ok });
    } catch (err) {
      set({ error: JSON.stringify(err), loading: ELoadingStatus.Fail });
    }
  },
});

export const useBoundViewAnswerStore = create<TSliceViewAnswer>()(
  devtools(
    immer((...a) => ({
      ...createViewAnswerSlice(...a),
    }))
  )
);

export * from './interfaces';
