import React from 'react';
import { Button, Typography } from 'antd';

import { IProps } from './interfaces';
import { useBoundUserStore } from '@store/slices';

const { Title, Paragraph } = Typography;

const Completed = ({ onSend, loading }: IProps) => {
  const { patient } = useBoundUserStore(({ patient }) => ({ patient }));

  return (
    <div
      style={{ textAlign: 'center', maxWidth: '556px', margin: '0 auto', paddingBottom: '42px', paddingTop: '24px' }}
    >
      <Title level={4}>{patient?.firstName}, спасибо за заполненный дневник</Title>
      <Paragraph type="secondary">
        Если не уверен(а) в ответах, пересмотри тест еще раз. Если возникли вопросы - запиши их себе и мы их обязательно
        разберем.
      </Paragraph>
      <div style={{ display: 'flex', marginTop: '36px', justifyContent: 'center' }}>
        <Button type="primary" onClick={onSend} size="large" loading={loading}>
          Отправить тест
        </Button>
      </div>
    </div>
  );
};

export default Completed;
