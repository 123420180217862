import React, { ReactElement } from 'react';

import CheckboxInput from './CheckboxInput';
import TextAreaInput from './TextAreaInput';
import NumberInput from './NumberInput';
import RadioInput from './RadioInput';
import { ETypeInput, TInputs } from './interfaces';

const FormBuilder = (props: TInputs): ReactElement => {
  const { type } = props;

  if (type === ETypeInput.Switch) {
    return <CheckboxInput {...props} />;
  }

  if (type === ETypeInput.Slider) {
    return <NumberInput {...props} />;
  }

  if (type === ETypeInput.Radio) {
    return <RadioInput {...props} />;
  }

  return <TextAreaInput {...props} />;
};

export default FormBuilder;
