import React, { useState } from 'react';
import { Button, Flex, Form, Input, Typography, message } from 'antd';
import { Link, useNavigate } from 'react-router-dom';

import { useBoundUserStore } from '@store/slices';
import { IFields } from '../SignIn/interfaces';

const { Title } = Typography;

const Forgot = () => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const { forgot } = useBoundUserStore(({ forgot }) => ({ forgot }));
  const [form] = Form.useForm<{ email: string }>();

  const onSubmit = (values: { email: string }) => {
    setLoading(true);

    forgot(values)
      .then(() => {
        navigate('/');
        setLoading(false);
      })
      .catch((err: Error | Record<keyof { email: string }, string>) => {
        if (typeof err === 'object' && err !== null) {
          const fields = Object.keys(err).reduce<IFields[]>((acc, key) => {
            // @ts-ignore
            const val = err[key];
            acc.push({ name: key, errors: [val] });

            return acc;
          }, []);

          form.setFields(fields);
        }

        setLoading(false);

        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        messageApi.open({
          type: 'error',
          content: 'This is an error message',
        });
      });
  };

  return (
    <Flex gap="middle" vertical>
      {contextHolder}
      <Title level={2} style={{ textAlign: 'center', marginBottom: '24px' }}>
        Восставновить пароль
      </Title>

      <Form
        name="basic"
        initialValues={{ email: '' }}
        onFinish={onSubmit}
        autoComplete="off"
        form={form}
        layout="vertical"
      >
        <Form.Item
          label="E-mail"
          name="email"
          required={false}
          rules={[
            {
              type: 'email',
              message: 'The input is not valid E-mail!',
            },
            {
              required: true,
              message: 'Please input your E-mail!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isLoading} style={{ width: '100%' }} size="large">
            Войти
          </Button>
        </Form.Item>
      </Form>
    </Flex>
  );
};

export default Forgot;
