import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Flex, Form, Input, message, Typography } from 'antd';

import { useBoundUserStore } from '@store/slices';

import { IResetPasswordParams } from './interfaces';
import { IFields } from '../SignIn/interfaces';

const { Title } = Typography;

const ResetPassword = () => {
  const { resetPassword } = useBoundUserStore(({ resetPassword }) => ({ resetPassword }));

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isLoading, setLoading] = useState(false);

  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm<{ password2: string; password: string }>();
  const hash = searchParams.get('hash') ?? '';

  const onSubmit = (values: IResetPasswordParams) => {
    setLoading(true);

    resetPassword({
      password: values.password,
      hash,
    })
      .then(() => {
        navigate('/auth/login');
        setLoading(false);
      })
      .catch((err: Error | Record<keyof IResetPasswordParams, string>) => {
        if (typeof err === 'object' && err !== null) {
          const fields = Object.keys(err).reduce<IFields[]>((acc, key) => {
            // @ts-ignore
            const val = err[key];
            acc.push({ name: key, errors: [val] });

            return acc;
          }, []);

          form.setFields(fields);
        }

        setLoading(false);

        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        messageApi.open({
          type: 'error',
          content: 'This is an error message',
        });
      });
  };

  return (
    <div>
      {contextHolder}
      <Title level={2} style={{ textAlign: 'center', marginBottom: '24px' }}>
        Изменить пароль
      </Title>

      <Form
        name="basic"
        initialValues={{ password2: '', password: '' }}
        onFinish={onSubmit}
        autoComplete="off"
        form={form}
        layout="vertical"
      >
        <Form.Item<IResetPasswordParams>
          name="password"
          label="Пароль"
          rules={[
            {
              required: true,
              message: 'Please input your password!',
            },
          ]}
          hasFeedback
        >
          <Input.Password />
        </Form.Item>

        <Form.Item<IResetPasswordParams>
          name="password2"
          label="Подтвердить пароль"
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Please confirm your password!',
            },
            ({ getFieldValue }) => ({
              async validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }

                return Promise.reject(new Error('The new password that you entered do not match!'));
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Сохранить
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ResetPassword;
