import React from 'react';
import { Button, Modal, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { IProps } from './interfaces';

const ModalComplete = ({ open }: IProps) => {
  const navigate = useNavigate();

  const handleClose = () => {
    navigate('/');
  };

  return (
    <Modal
      open={open}
      onOk={handleClose}
      onCancel={handleClose}
      footer={[
        <Button key="submit" type="primary" onClick={handleClose}>
          Ок, понятно
        </Button>,
      ]}
    >
      <Typography.Title level={4} style={{ textAlign: 'center' }}>
        Ура, тест успешно отправлен!
      </Typography.Title>
      <Typography.Paragraph type="secondary" style={{ textAlign: 'center' }}>
        {/* Ты заполняешь карточку без пропусков уже 18 дней. <br /> */}
        Ты - молодечик :)
      </Typography.Paragraph>
    </Modal>
  );
};

export default ModalComplete;
