import React, { useEffect, useMemo, useState } from 'react';
import { useBoundViewAnswerStore } from '@store/slices/viewAnswer';
import { Button, Modal, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';

import { ELoadingStatus } from '@src/interfaces/common';
import { useBoundAnswersStore } from '@store/slices/answers';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import ruRU from 'dayjs/locale/ru';
import weekOfYear from 'dayjs/plugin/weekOfYear';

import { IProps } from './interfaces';
import List from '../List';
import styles from './styles.module.less';
import { useBoundPollsStore } from '@store/slices';

dayjs.extend(weekOfYear);

const AnswersModal = ({ isOpen, closeModal }: IProps) => {
  const navigate = useNavigate();
  const { item, loading, getAnswer } = useBoundViewAnswerStore((state) => state);
  const { data, maps, date } = useBoundAnswersStore(({ data, maps, date }) => ({ data, maps, date }));
  const onSetAnswer = useBoundPollsStore(({ onSetAnswer }) => onSetAnswer);

  const [currentId, setCurrentId] = useState(maps.get(date.format('DD-MM-YYYY')) ?? 0);
  const [currentDate, setCurrentDate] = useState(date.toISOString());

  useEffect(() => {
    if (currentId) {
      getAnswer(String(currentId));
    }
  }, [currentId]);

  const isLoading = loading === ELoadingStatus.Pending;

  const onPrev = () => {
    const idx = data.findIndex(({ id }) => id === currentId);
    const index = idx - 1;

    if (data[index]) {
      setCurrentId(data[index].id);
      setCurrentDate(data[index].day);
    }
  };

  const onNext = () => {
    const idx = data.findIndex(({ id }) => id === currentId);
    const index = idx + 1;

    if (data[index]) {
      setCurrentId(data[index].id);
      setCurrentDate(data[index].day);
    }
  };

  const onClickEdit = () => {
    if (item?.fields) {
      onSetAnswer(item.fields);
    }

    navigate('polls', { state: { day: currentDate, editId: currentId } });
  };

  const isNowWeek = useMemo(() => dayjs(currentDate).week() === dayjs().week(), [currentDate]);

  return (
    <Modal
      className={styles.modal}
      title={
        <Typography.Title level={4}>
          Просмотр на{' '}
          <Typography.Text type="secondary" style={{ fontWeight: 600, fontSize: '16px', lineHeight: '24px' }}>
            {dayjs(currentDate).locale(ruRU).format('DD MMMM')}
          </Typography.Text>
        </Typography.Title>
      }
      footer={
        isNowWeek ? (
          <Button type="primary" onClick={onClickEdit}>
            Изменить ответы
          </Button>
        ) : null
      }
      loading={isLoading}
      open={isOpen}
      onCancel={closeModal}
    >
      <div className={styles.btns}>
        <Button className={styles.left} shape="circle" icon={<LeftOutlined />} size="large" onClick={onPrev} />
        <Button className={styles.right} shape="circle" icon={<RightOutlined />} size="large" onClick={onNext} />
      </div>
      <div style={{ marginTop: '24px' }}>{item?.fields && <List fields={item?.fields} />}</div>
    </Modal>
  );
};

export default AnswersModal;
