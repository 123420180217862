/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable @typescript-eslint/no-redundant-type-constituents */
/* eslint-disable new-cap */
/* eslint-disable curly */
import * as React from 'react';
import { useMemo } from 'react';
import { InputRef, theme, Input, InputProps } from 'antd';
import IMask, { MaskedOptions } from 'imask';

export interface MaskedInputProps extends Omit<InputProps, 'onChange' | 'value' | 'defaultValue'> {
  mask: MaskType;
  // @ts-ignore
  definitions?: InputMaskOptions['definitions'];
  value?: string;
  defaultValue?: string;
  maskOptions?: InputMaskOptions;
  onChange?: (event: OnChangeEvent) => any;
}

export const MaskedInput = React.forwardRef<InputRef, MaskedInputProps>((props: MaskedInputProps, antdRef) => {
  const { mask, maskOptions: _maskOptions, value: _value, defaultValue, definitions, ...antdProps } = props;
  const { token } = theme.useToken();

  const innerRef = React.useRef<HTMLInputElement | null>(null);

  // @ts-ignore
  const maskOptions: IMaskOptions = useMemo(() => {
    return {
      mask,
      definitions: {
        0: /[0-9]/,
        // @ts-ignore
        ..._maskOptions?.definitions,
        ...definitions,
      },
      lazy: false, // make placeholder always visible
      ..._maskOptions,
    };
  }, [mask]);

  const placeholder = useMemo(() => {
    return IMask.createPipe({ ...maskOptions, lazy: false } as any)('');
  }, [maskOptions]);

  // @ts-ignore
  const imask = React.useRef<IMask.InputMask<any> | null>(null);

  const propValue = (typeof _value === 'string' ? _value : defaultValue) || '';

  const lastValue = React.useRef(propValue);

  const [value, setValue] = React.useState(propValue);

  const _onEvent = React.useCallback((ev: any, execOnChangeCallback = false) => {
    const masked = imask.current;
    if (!masked) return;

    if (ev.target) {
      if (ev.target.value !== masked.value) {
        masked.value = ev.target.value;
        ev.target.value = masked.value;
        lastValue.current = masked.value;
      }
    }

    Object.assign(ev, {
      maskedValue: masked.value,
      unmaskedValue: masked.unmaskedValue,
    });

    masked.updateValue();
    setValue(lastValue.current);

    if (execOnChangeCallback) {
      props.onChange?.(ev);
    }
  }, []);

  const _onAccept = React.useCallback((ev: any) => {
    if (!ev?.target) return;

    const input = innerRef.current;
    const masked = imask.current;
    if (!input || !masked) return;

    ev.target.value = masked.value;
    input.value = masked.value;
    lastValue.current = masked.value;

    _onEvent(ev, true);
  }, []);

  function updateMaskRef() {
    const input = innerRef.current;

    if (imask.current) {
      imask.current.updateOptions(maskOptions as any);
    }

    if (!imask.current && input) {
      imask.current = IMask<any>(input, maskOptions);
      imask.current.on('accept', _onAccept);
    }

    if (imask.current && imask.current.value !== lastValue.current) {
      imask.current.value = lastValue.current;
      imask.current.alignCursor();
    }
  }

  function updateValue(value: string) {
    lastValue.current = value;
    const input = innerRef.current;
    const masked = imask.current;
    if (!(input && masked)) return;
    masked.value = value;
    // updating value with the masked
    //   version (imask.value has a setter that triggers masking)
    input.value = masked.value;
    lastValue.current = masked.value;
  }

  React.useEffect(() => {
    updateMaskRef();

    return () => {
      imask.current?.destroy();
      imask.current = null;
    };
  }, [mask]);

  React.useEffect(() => {
    updateValue(propValue);
  }, [propValue]);

  const eventHandlers = React.useMemo(() => {
    return {
      onBlur(ev: any) {
        _onEvent(ev);
        props.onBlur?.(ev);
      },

      onPaste(ev: React.ClipboardEvent<HTMLInputElement>) {
        lastValue.current = ev.clipboardData?.getData('text');

        if (ev.target) {
          // @ts-ignore
          ev.target.value = lastValue.current;
        }

        _onEvent(ev, true);
        props.onPaste?.(ev);
      },

      onFocus(ev: any) {
        _onEvent(ev);
        props.onFocus?.(ev);
      },
    };
  }, []);

  return (
    <Input
      placeholder={placeholder}
      {...antdProps}
      {...eventHandlers}
      onChange={(ev) => _onEvent(ev, true)}
      value={value}
      ref={(ref) => {
        if (antdRef) {
          if (typeof antdRef === 'function') {
            antdRef(ref);
          } else {
            antdRef.current = ref;
          }
        }

        if (ref?.input) {
          innerRef.current = ref.input;
          if (!imask.current) {
            updateMaskRef();
          }
        }
      }}
    />
  );
});

export default MaskedInput;

export type UnionToIntersection<T> = (T extends any ? (x: T) => any : never) extends (x: infer R) => any
  ? {
      [K in keyof R]: R[K];
    }
  : never;

type OnChangeParam = Parameters<Exclude<InputProps['onChange'], undefined>>[0];

interface OnChangeEvent extends OnChangeParam {
  maskedValue: string;
  unmaskedValue: string;
}

interface IMaskOptionsBase extends UnionToIntersection<MaskedOptions> {}

export type InputMaskOptions = {
  [K in keyof IMaskOptionsBase]?: IMaskOptionsBase[K];
};

type MaskFieldType = string | RegExp | Function | Date | InputMaskOptions;

interface IMaskOptions extends Omit<InputMaskOptions, 'mask'> {
  mask: MaskFieldType;
}

interface MaskOptionsList extends Array<IMaskOptions> {}

export type MaskType = MaskFieldType | MaskOptionsList;
