import React, { ReactElement, useState } from 'react';
import { Input, Typography } from 'antd';

import { ITextAreaInput } from '../interfaces';
import { TextAreaProps } from 'antd/es/input';

const MAX_COUNT = 200;
const { TextArea } = Input;
const { Text } = Typography;

const TextAreaInput = ({
  defaultValue = '',
  placeholder,
  onChange,
  title,
  description,
  fieldKey,
}: ITextAreaInput): ReactElement => {
  const [value, setValue] = useState(defaultValue);

  const handleChange: TextAreaProps['onChange'] = (event): void => {
    setValue(event.target.value);

    onChange?.(fieldKey, event.target.value);
  };

  return (
    <div>
      {title && <Text>{title}</Text>}
      <TextArea
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        autoSize={{ minRows: 3, maxRows: 5 }}
        maxLength={MAX_COUNT}
        count={{ max: 100, show: true }}
      />
      {description && <Text type="secondary">{description}</Text>}
    </div>
  );
};

export default TextAreaInput;
