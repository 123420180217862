import React, { ReactElement, useState } from 'react';
import classNames from 'classnames';

import { ReactComponent as VerySadIcon } from './assets/very_sad.svg';
import { ReactComponent as VeryHappyIcon } from './assets/very_happy.svg';
import { ReactComponent as SadIcon } from './assets/sad.svg';
import { ReactComponent as HappyIcon } from './assets/happy.svg';
import { ReactComponent as IndifferentIcon } from './assets/indifferent.svg';
import styles from './styles.module.less';
import { useBoundUserStore } from '@store/slices';
import { Grid, Typography } from 'antd';
import { IProps } from './interfaces';

const { useBreakpoint } = Grid;

const Mood = ({ onChange }: IProps): ReactElement => {
  const { md } = useBreakpoint();
  const { patient } = useBoundUserStore(({ patient }) => ({ patient }));
  const [mood, setMood] = useState('neutral');

  const onClick = (mood: string) => () => {
    setMood(mood);
    onChange('mood', mood);
  };

  const list = [
    { key: 'empty', icon: <VerySadIcon /> },
    { key: 'sad', icon: <SadIcon /> },
    { key: 'neutral', icon: <IndifferentIcon /> },
    { key: 'smile', icon: <HappyIcon /> },
    { key: 'happy', icon: <VeryHappyIcon /> },
  ];

  return (
    <div className={classNames(styles.mood, { _mobile: !md })}>
      <Typography.Title level={3}>Здравствуйте, {patient?.firstName}! Как Вы сегодня?</Typography.Title>

      <div className={styles.list}>
        {list.map(({ key, icon }) => (
          <div className={classNames(styles.face, { _active: key === mood })} onClick={onClick(key)} key={key}>
            {icon}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Mood;
