import React, { useState } from 'react';
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { Radio, Space, Typography } from 'antd';
import { RadioGroupProps } from 'antd/lib';
import { IRadioInput } from '../interfaces';

const { Text } = Typography;

const RadioInput = ({ defaultValue = 0, list, onChange, title, fieldKey }: IRadioInput) => {
  const [value, setValue] = useState<number>(defaultValue);

  const handleChange: RadioGroupProps['onChange'] = ({ target }): void => {
    setValue(target.value);
    onChange?.(fieldKey, target.value);
  };

  return (
    <div style={{ marginBottom: '24px' }}>
      {title && <Text style={{ display: 'block', marginBottom: '12px', fontSize: '16px' }}>{title}</Text>}

      <Radio.Group onChange={handleChange} value={value}>
        <Space direction="vertical" size={12}>
          {list.map(({ title, val }) => (
            <Radio value={val} key={title} style={{ fontSize: '16px' }}>
              {title}
            </Radio>
          ))}
        </Space>
      </Radio.Group>
    </div>
  );
};

export default RadioInput;
