import { TPrimitive } from '@src/interfaces/common';

export enum ETypeInput {
  Slider = 'slider',
  Switch = 'switch',
  TextArea = 'textArea',
  Radio = 'radio',
}

export interface IRadioListItem {
  title: string;
  val: number;
}

export interface IRadioInput extends ICommonPropsInputs<number> {
  type: ETypeInput.Radio;
  list: IRadioListItem[];
}

export interface INumberInput extends ICommonPropsInputs<number> {
  type: ETypeInput.Slider;
  min?: number;
  max?: number;
}

export interface ITextAreaInput extends ICommonPropsInputs<string> {
  type: ETypeInput.TextArea;
}

export interface ICheckboxInput extends ICommonPropsInputs<boolean> {
  type: ETypeInput.Switch;
  about?: {
    type: ETypeInput.TextArea;
    placeholder: string;
    key: string;
  };
}

export interface ICommonPropsInputs<T> {
  fieldKey: string;
  title?: string;
  description?: string;
  defaultValue?: T;
  defaultValueAbout?: string;
  placeholder?: string;
  onChange?: (key: string, val: T) => void;
  value?: TPrimitive;
}

export type TInputs = INumberInput | ITextAreaInput | ICheckboxInput | IRadioInput;
