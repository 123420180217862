import React from 'react';
import dayjs from 'dayjs';
import { DatePicker, Typography } from 'antd';
import { DatePickerProps } from 'antd/lib';
import { RangePickerProps } from 'antd/es/date-picker';
import { CalendarOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';

import { useBoundAnswersStore } from '@store/slices/answers';
import { ELoadingStatus } from '@src/interfaces/common';

import styles from './styles.module.less';

const { Title } = Typography;

const Picker = () => {
  const { maps, loading, setDate, date } = useBoundAnswersStore(({ maps, loading, setDate, date }) => ({
    maps,
    loading,
    setDate,
    date,
  }));

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    // Can not select days before today and today
    // current && current < dayjs().add(3, 'week')
    return current && current > dayjs();
  };

  const onChange: DatePickerProps['onChange'] = (d) => {
    if (d) {
      setDate(d);
    }
  };

  return (
    <div className={styles.datapicker}>
      <Title level={4} type="secondary" style={{ marginBottom: '10px' }}>
        Выберите дату:
      </Title>
      <DatePicker
        disabled={loading === ELoadingStatus.Pending}
        className={styles.picker}
        popupClassName="date-picker"
        inputReadOnly
        disabledDate={disabledDate}
        variant="borderless"
        onChange={onChange}
        value={date}
        size="large"
        format="DD MMMM YYYY"
        placement="topRight"
        suffixIcon={<CalendarOutlined style={{ width: '24px', height: '24px' }} size={24} height={24} />}
        superNextIcon={null}
        superPrevIcon={null}
        allowClear={false}
        showNow={false}
        prevIcon={<LeftOutlined size={24} />}
        nextIcon={<RightOutlined size={24} />}
        cellRender={(current, info) => {
          const { originNode } = info;

          const currentDate = maps.has(dayjs(current).format('DD-MM-YYYY'));

          if (currentDate) {
            return React.cloneElement(originNode, {
              className: 'cell-fill',
            });
          }

          return originNode;
        }}
      />
    </div>
  );
};

export default Picker;
