import React, { ReactElement, useMemo } from 'react';
import { Flex, Typography, Checkbox, Radio } from 'antd';

import { useBoundPollsStore } from '@store/slices';
import { ETypeInput, INumberInput, IRadioInput } from '@components/UI/FormBuilder/interfaces';
import { TPrimitive } from '@src/interfaces/common';

import { ReactComponent as VerySadIcon } from '@assets/mood/very_sad.svg';
import { ReactComponent as VeryHappyIcon } from '@assets/mood/very_happy.svg';
import { ReactComponent as SadIcon } from '@assets/mood/sad.svg';
import { ReactComponent as HappyIcon } from '@assets/mood/happy.svg';
import { ReactComponent as IndifferentIcon } from '@assets/mood/indifferent.svg';

import { setPathObject, parseData } from './utils';
import { IAnswerList, IAnswerPollItem, IProps } from './interfaces';
import styles from './styles.module.less';

const { Title, Text } = Typography;

const List = ({ fields }: IProps): ReactElement => {
  const { items: polls } = useBoundPollsStore(({ items }) => ({ items }));
  const hashMapFields = Object.keys(fields).reduce<Record<string, TPrimitive>>((acc, path) => {
    setPathObject(acc, path, fields[path]);

    return acc;
  }, {});

  const data = useMemo(() => parseData(polls, hashMapFields), [polls, hashMapFields]);

  const renderChild = (child: IAnswerPollItem): ReactElement => {
    const { title, key } = child;
    const { value } = child.value;

    if (child.type === ETypeInput.Switch) {
      return (
        <div key={key}>
          <Checkbox checked={value as boolean} style={{ fontSize: '16px' }}>
            {title}
          </Checkbox>

          {child.value.about && <Typography.Paragraph>Текст: {child.value.about.value}</Typography.Paragraph>}
        </div>
      );
    }

    if (child.type === ETypeInput.Radio) {
      // @ts-ignore
      const currentTitle = (child as IRadioInput).list[value as number].title;

      return (
        <div key={key}>
          <Text style={{ display: 'block', marginBottom: '12px', fontSize: '16px' }}>{title}</Text>
          <Radio checked={true} style={{ fontSize: '16px' }}>
            {currentTitle}
          </Radio>
        </div>
      );
    }

    // @ts-ignore
    const { max } = child as INumberInput;

    return (
      <div key={key} className={styles.numberField}>
        <Text>{title}:</Text>
        <div className={styles.line} />
        <Text>
          {value}/{max}
        </Text>
      </div>
    );
  };

  const renderItem = ({ title, key, children }: IAnswerList): ReactElement => {
    return (
      <Flex key={key} vertical={true} gap={24}>
        <Title level={4} className={styles.title}>
          {title}
        </Title>
        {children.map(renderChild)}
      </Flex>
    );
  };

  const moodIcons: Record<string, ReactElement> = {
    empty: <VerySadIcon />,
    sad: <SadIcon />,
    neutral: <IndifferentIcon />,
    smile: <HappyIcon />,
    happy: <VeryHappyIcon />,
  };

  const moodType = fields?.mood as string;

  return (
    <div className={styles.list}>
      <div className={styles.mood}>{moodIcons[moodType || 'empty']}</div>
      {data?.map(renderItem)}
    </div>
  );
};

export default List;
