import axios, { AxiosInstance } from 'axios';

import axiosInstance, { instanceOptions } from '@services/axios';
import { getStorageToken, removeStorageToken, setStorageToken } from '@utils/storageUser';
import {
  IForgotPasswordPayload,
  ILoginRequest,
  IPayloadChaneUserInfo,
  IPaylodChangePassword,
  IResetPasswordPayload,
  LoginResponseType,
  TUserMeReponse,
  TUserResponse,
} from './interfaces';

class UserService {
  authInstance: AxiosInstance;

  constructor() {
    this.authInstance = axios.create(instanceOptions);
  }

  async changePassword(params: IPaylodChangePassword): Promise<TUserResponse> {
    const response = await axiosInstance.patch<TUserResponse>('/auth/me', params);

    if (!response.data.data) {
      throw new Error('notFound');
    }

    return response.data;
  }

  async changeUserInfo(params: IPayloadChaneUserInfo): Promise<TUserResponse> {
    const response = await axiosInstance.patch<TUserResponse>(`/users/${params.userId}/email`, { email: params.email });

    if (!response.data.data) {
      throw new Error('notFound');
    }

    return response.data;
  }

  async login(params: ILoginRequest): Promise<TUserResponse> {
    const response = await this.authInstance.post<TUserResponse>('/auth/email/login', params);

    if (!response.data.data) {
      throw new Error('notFound');
    }

    return response.data;
  }

  async resetPassword(params: IResetPasswordPayload): Promise<TUserResponse> {
    const response = await this.authInstance.post<TUserResponse>('/auth/reset/password', params);

    if (!response.data.data) {
      throw new Error('notFound');
    }

    return response.data;
  }

  async forgot(params: IForgotPasswordPayload): Promise<TUserResponse> {
    const response = await this.authInstance.post<TUserResponse>('/auth/forgot/password', params);

    if (!response.data.data) {
      throw new Error('notFound');
    }

    return response.data;
  }

  async fetchUserMe() {
    const response = await axiosInstance.get<TUserMeReponse>('/auth/me');

    if (!response.data) {
      throw new Error('notFound');
    }

    return response.data;
  }

  async refreshToken(): Promise<string> {
    const { refreshToken } = getStorageToken();

    if (!refreshToken) {
      removeStorageToken();
      // TODO: to auth/login
      throw new Error('tokenNotFound');
    }

    const options = {
      ...instanceOptions,
      headers: {
        ...instanceOptions.headers,
        Authorization: `Bearer ${refreshToken}`,
      },
    };

    const api = axios.create(options);
    const response = await api.post<Omit<LoginResponseType, 'user'>>('/auth/refresh');

    if (!response.data) {
      throw new Error('userInvalid');
    }

    setStorageToken(response.data);

    return response.data.token;
  }
}

export const User = new UserService();
