import { Patients } from './Patients';
import { Polls } from './Polls';
import { User } from './User';
import { Answers } from './Answers';

class Api {
  patients: typeof Patients = Patients;
  polls: typeof Polls = Polls;
  user: typeof User = User;
  answers: typeof Answers = Answers;
}

export default new Api();
