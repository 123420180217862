import React, { ReactElement, useState } from 'react';
import { Checkbox } from 'antd';
import { CheckboxProps } from 'antd/lib';

import TextAreaInput from '../TextAreaInput';
import { ICheckboxInput } from '../interfaces';
import styles from './styles.module.less';

const CheckboxInput = ({
  defaultValue = false,
  onChange,
  title,
  about,
  fieldKey,
  defaultValueAbout,
}: ICheckboxInput): ReactElement => {
  const [checked, setChecked] = useState(defaultValue);

  const handleChange: CheckboxProps['onChange'] = (event): void => {
    setChecked(event.target.checked);
    onChange?.(fieldKey, event.target.checked);
  };

  return (
    <div>
      <Checkbox onChange={handleChange} checked={checked} className={styles.checked}>
        {title}
      </Checkbox>

      {about && checked && (
        <div className={styles.textarea}>
          <TextAreaInput
            {...about}
            onChange={onChange as any}
            fieldKey={about.key}
            key={about.key}
            defaultValue={defaultValueAbout}
          />
        </div>
      )}
    </div>
  );
};

export default CheckboxInput;
