import { create, StateCreator } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import Api from '@services/index';

import { TSlicePolls } from './interfaces';
import { useBoundUserStore } from '../user';
import { ETypeInput } from '@components/UI/FormBuilder/interfaces';

const createPollsSlice: StateCreator<
  TSlicePolls,
  [['zustand/immer', TSlicePolls], ['zustand/devtools', never]],
  [],
  TSlicePolls
> = (set) => ({
  items: [],
  answers: {},
  isLoading: false,
  error: '',
  onSetAnswer: (fields) => {
    set({ answers: fields });
  },
  onChangeAnswer: (key: string, value: boolean | number | string) => {
    set((state) => {
      state.answers[key] = value;

      return state;
    });
  },
  getPollsList: async () => {
    set({ isLoading: true });
    const { patient } = useBoundUserStore.getState();

    try {
      const { data } = await Api.polls.fetchPollsList(patient?.id ?? '');

      if (!data) {
        throw new Error('error');
      }

      const prepareAnswers = data
        .flatMap(({ children }) => children)
        .reduce<Record<string, boolean | number | string>>(
          (acc, item) => {
            const { key, type } = item;

            if (type === ETypeInput.Slider) {
              acc[key] = 3;
            }

            if (type === ETypeInput.Switch) {
              acc[key] = false;

              if (item?.about) {
                acc[item.about.key] = '';
              }
            }

            if (type === ETypeInput.TextArea) {
              acc[key] = '';
            }

            if (type === ETypeInput.Radio) {
              acc[key] = 0;
            }

            return acc;
          },
          { mood: 'neutral' }
        );

      set({ isLoading: false, items: data, answers: prepareAnswers });
    } catch (error) {
      set({ isLoading: false, error: JSON.stringify(error) });
    }
  },
});

export const useBoundPollsStore = create<TSlicePolls>()(
  devtools(
    immer((...a) => ({
      ...createPollsSlice(...a),
    }))
  )
);

export * from './interfaces';
