import React from 'react';
import { Divider, Flex, Typography, Row, Col, Grid } from 'antd';
import classNames from 'classnames';
import { useBoundUserStore } from '@store/slices';

import NotFound from '@components/NotFound';
import Picker from './Picker';
import Bottom from './Bottom';
import styles from './styles.module.less';

const { Title, Text } = Typography;
const { useBreakpoint } = Grid;

const Dashboard = () => {
  const { md } = useBreakpoint();

  const patient = useBoundUserStore(({ patient }) => patient);

  if (!patient) {
    return <NotFound />;
  }

  const { doctors, trainers } = patient;

  return (
    <div className={classNames(styles.container, { _mobile: !md })}>
      <div className={styles.content}>
        <Row justify="space-between" wrap={true} gutter={[24, 24]}>
          <Col
            xs={24}
            md={{
              flex: 'initial',
            }}
          >
            {doctors?.map(({ firstName, lastName, middleName, phone }, index: number) => (
              <Flex gap={6} vertical key={index}>
                <Text type="secondary" strong className={styles.subtitle}>
                  Индивидуальный терапевт:
                </Text>
                <Title level={3}>
                  {firstName} {lastName} {middleName}
                </Title>
                <Text type="secondary" className={styles.text}>
                  Телефон: <a href={`tel:${phone}`}>{phone}</a>
                </Text>
                <Divider style={{ margin: '12px 0 12px' }} />
              </Flex>
            ))}

            {trainers?.map(({ firstName, lastName, middleName, phone }, index: number) => (
              <Flex gap={6} vertical key={index}>
                <Text type="secondary" strong className={styles.subtitle}>
                  Коуч-тренер:
                </Text>
                <Title level={3}>
                  {lastName} {firstName} {middleName}
                </Title>
                <Text type="secondary" className={styles.text}>
                  Телефон: <a href={`tel: ${phone}`}>{phone}</a>
                </Text>
                <Divider style={{ margin: '12px 0 12px' }} />
              </Flex>
            ))}

            <Flex gap={6} vertical>
              <Text className={styles.subtitle} type="secondary" strong>
                Экстренные номера:{' '}
              </Text>
              <Text type="secondary" className={styles.text}>
                <a href="tel:8495051">8 (495) 051</a> (Москва){' '}
              </Text>
              <Text type="secondary" className={styles.text}>
                <a href="tel:89999999999">246-27-80</a> (Санкт-Петербург)
              </Text>
              <Text type="secondary" className={styles.text}>
                <a href="tel:89999999999">8-800-2000-122</a> (Россия)
              </Text>
            </Flex>
          </Col>
          <Col
            xs={24}
            md={{
              flex: '308px',
            }}
          >
            <Picker />
          </Col>
        </Row>

        <Bottom />
      </div>
    </div>
  );
};

export default Dashboard;
