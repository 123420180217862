import React, { useState } from 'react';
import { Slider, Typography } from 'antd';

import { INumberInput } from '../interfaces';
import styles from './styles.module.less';

const { Text } = Typography;

const NumberInput = ({ min = 0, max = 7, defaultValue = 3, onChange, title, description, fieldKey }: INumberInput) => {
  const [value, setValue] = useState<number>(defaultValue);

  const handleChange = (val: number | null): void => {
    if (val === null) {
      return;
    }

    setValue(val);
    onChange?.(fieldKey, val);
  };

  return (
    <div className={styles['form-input']}>
      {title && <Text className={styles.title}>{title}</Text>}

      <div className={styles.slider}>
        <Slider
          value={value}
          onChange={handleChange}
          max={max}
          min={min}
          marks={{ [min]: String(min), [max]: String(max) }}
        />
      </div>
    </div>
  );
};

export default NumberInput;
