import { create, StateCreator } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import Api from '@services/index';

import { TSliceAnswers } from './interfaces';
import { ELoadingStatus } from '@src/interfaces/common';
import dayjs from 'dayjs';
import { useBoundPollsStore } from '../polls';
import { enableMapSet } from 'immer';

enableMapSet();

const createAnswersSlice: StateCreator<
  TSliceAnswers,
  [['zustand/immer', TSliceAnswers], ['zustand/devtools', never]],
  [],
  TSliceAnswers
> = (set, get) => ({
  answers: {},
  data: [],
  maps: new Map(),
  loading: ELoadingStatus.Pending,
  error: '',
  date: dayjs(),
  setDate: (date) => {
    set({ date });
  },
  sendAnswers: async (answerId) => {
    try {
      const { date } = get();
      const fields = useBoundPollsStore.getState().answers;

      if (Number.isInteger(answerId)) {
        const { data: updateData } = await Api.answers.updateAnswers({ id: answerId ?? 0, fields });

        if (!updateData) {
          throw new Error('error');
        }

        return;
      }

      const day = dayjs(date);
      const { data } = await Api.answers.saveAnswers({ fields, day: day.toISOString() });

      if (!data) {
        throw new Error('error');
      }

      set((state) => {
        state.data.push(data);
        state.maps = new Map(state.maps).set(day.format('DD-MM-YYYY'), data.id);
      });
    } catch (err) {
      set({ error: JSON.stringify(err) });
    }
  },
  getAnswers: async () => {
    set({ loading: ELoadingStatus.Pending });

    try {
      const { data } = await Api.answers.getAnswers();

      if (!data) {
        throw new Error('error');
      }

      const maps = new Map(get().maps);

      data.forEach(({ day, id }) => {
        if (!maps.has(day)) {
          maps.set(dayjs(day).format('DD-MM-YYYY'), id);
        }
      });

      set({ data, maps, loading: ELoadingStatus.Ok });
    } catch (err) {
      set({ error: JSON.stringify(err), loading: ELoadingStatus.Fail });
    }
  },
});

export const useBoundAnswersStore = create<TSliceAnswers>()(
  devtools(
    immer((...a) => ({
      ...createAnswersSlice(...a),
    }))
  )
);

export * from './interfaces';
