import React from 'react';
import { Button, Divider, Flex, Typography, theme } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import { IProps } from './interfaces';

const Forms = ({ onStep, children, step, max, isLoading }: IProps) => (
  <div>
    <Divider />
    {children}

    <Divider />

    <Flex justify="space-between">
      <Button type="default" icon={<LeftOutlined />} onClick={onStep('prev')} loading={isLoading}>
        Назад
      </Button>
      {step !== max && (
        <Button type="primary" onClick={onStep('next')} loading={isLoading}>
          Дальше
          <RightOutlined />
        </Button>
      )}
    </Flex>
  </div>
);

export default Forms;
