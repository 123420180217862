import React, { ReactElement, useMemo, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Steps, Row, Col, Flex, Typography, theme, Grid } from 'antd';
import dayjs from 'dayjs';
import ruRU from 'dayjs/locale/ru';

import { useBoundPollsStore } from '@store/slices/polls';
import { useBoundAnswersStore } from '@store/slices/answers';

import FormElement from './FormElement';
import Completed from './Completed';
import Mood from './Mood';
import Forms from './Forms';
import ModalComplete from './ModalComplete';

import styles from './styles.module.less';
import { subTitles } from './utils';
import classNames from 'classnames';

const { Text, Title, Paragraph } = Typography;
const { useBreakpoint } = Grid;

const Polls = (): ReactElement => {
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const { sendAnswers, date } = useBoundAnswersStore(({ sendAnswers, date }) => ({ sendAnswers, date }));
  const { items, onChangeAnswer } = useBoundPollsStore(({ items, onChangeAnswer }) => ({
    items,
    onChangeAnswer,
  }));
  const [step, setStep] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const { md } = useBreakpoint();
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  if (!date) {
    return <Navigate to="/" replace />;
  }

  const onChangeForm = (key: string, val: string | number | boolean): void => {
    onChangeAnswer(key, val);
  };

  const onSend = () => {
    setIsLoading(true);

    sendAnswers(location.state?.editId)
      .then(() => {
        setOpenModal(true);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const stepsList = useMemo(
    () => [
      { title: 'Настроение', className: styles['polls-step'] },
      ...items.map(({ title }) => ({ title, className: styles['polls-step'] })),
      { title: 'Результат', className: styles['polls-step'] },
    ],
    [items]
  );
  const forms = [
    <Mood onChange={onChangeForm} />,
    ...items.map((item) => <FormElement current={item} onChange={onChangeForm} />),
    <Completed onSend={onSend} loading={isLoading} />,
  ];

  const handleOnStep = (nav: 'prev' | 'next') => () => {
    if (step === 0 && nav === 'prev') {
      navigate(-1);
      return;
    }

    window.scrollTo(0, 0);

    if (nav === 'prev') {
      setStep(Math.max(step - 1, 0));
      return;
    }

    setStep(Math.min(step + 1, forms.length));
  };

  const handleChangeStep = (value: number) => {
    setStep(value);
  };

  return (
    <div>
      {openModal && <ModalComplete open={openModal} setOpen={setOpenModal} />}

      <Row gutter={24}>
        {md && (
          <Col lg={4}>
            <Steps
              progressDot
              current={step}
              direction="vertical"
              items={stepsList}
              onChange={handleChangeStep}
              style={{ position: 'sticky', top: '10px' }}
            />
          </Col>
        )}

        <Col lg={20} sm={24} xs={24}>
          <div className={classNames(styles.forms, { _mobile: !md })} style={{ background: colorBgContainer }}>
            <div>
              <Flex justify="space-between">
                <Title level={3} style={{ marginTop: 0 }}>
                  Карточка ДБТ
                </Title>
                <Text type="secondary" style={{ fontWeight: 600, fontSize: '16px', lineHeight: '24px' }}>
                  {dayjs(date || '')
                    .locale(ruRU)
                    .format('DD MMMM')}
                </Text>
              </Flex>

              <Paragraph type="secondary" style={{ fontSize: '16px' }}>
                {subTitles[step] || ''}
              </Paragraph>
            </div>

            <Forms onStep={handleOnStep} max={forms.length - 1} step={step} isLoading={isLoading}>
              {forms[step]}
            </Forms>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Polls;
