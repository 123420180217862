import { IPoll } from '@services/Polls/interfaces';
import { IAnswerList, IAnswerPollItem, IAnswerValue } from './interfaces';
import { ETypeInput } from '@components/UI/FormBuilder/interfaces';

export const setPathObject = (obj: Record<string, any>, path: string, value: unknown): Record<string, unknown> => {
  const pathArr = path.split('.').reverse();
  let current = obj;

  while (pathArr.length > 0) {
    const key = pathArr.pop();

    if (!key) {
      break;
    }

    if (pathArr.length === 0) {
      current[key] = { value };
      break;
    }

    if (current[key] && Object.prototype.hasOwnProperty.call(current, key)) {
      current = current[key];
    } else {
      current[key] = {};
      current = current[key];
    }
  }

  return obj;
};

export const getValue = (obj: Record<string, any>, path: string) => {
  const pathArr = path.split('.');

  return pathArr.reduce((acc, pathPart) => {
    if (acc) {
      acc = acc[pathPart];
    }

    return acc;
  }, obj);
};

export const parseData = (polls: IPoll[], hashMap: Record<string, unknown>) => {
  const data: IAnswerList[] = [];

  polls.forEach((poll) => {
    const { key, children } = poll;

    if (key in hashMap) {
      const current = hashMap[key] as Record<string, IAnswerValue>;
      const keys = Object.keys(current);
      const filtredChildren = children.reduce<IAnswerPollItem[]>((acc, child) => {
        const [_, childKey] = child.key.split('.');

        if (child?.type === ETypeInput.Switch && current[childKey]?.value === false) {
          return acc;
        }

        if (keys.includes(childKey)) {
          acc.push({ ...child, value: current[childKey], fieldKey: child.key });
        }

        return acc;
      }, []);

      const item = { ...poll, children: filtredChildren };
      data.push(item);
    }
  });

  return data;
};
