import axiosInstance from '@services/axios';
import { IUpdatePatient, TPatientsResponse } from './interfaces';

class PatientsService {
  async fetchPatient(): Promise<TPatientsResponse> {
    const response = await axiosInstance.get<TPatientsResponse>('/patients/client/one');

    return response.data;
  }

  async updatePatient(params: IUpdatePatient): Promise<TPatientsResponse> {
    const { id, ...rest } = params;
    const response = await axiosInstance.patch<TPatientsResponse>(`/patients/client/${id}`, rest);

    return response.data;
  }
}

export const Patients = new PatientsService();
