import React, { useState } from 'react';
import { Button, Divider, Flex, Form, Input, Typography, message } from 'antd';
import { useBoundUserStore } from '@store/slices/user';
import { IChangeEmail } from './interfaces';
import { EditOutlined, SaveOutlined } from '@ant-design/icons';

const ChangeEmail = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [edit, setEdit] = useState(false);
  const { changeEmail, user } = useBoundUserStore(({ changeEmail, user }) => ({ changeEmail, user }));
  const [form] = Form.useForm<IChangeEmail>();

  const onToggle = () => {
    setEdit(!edit);
  };

  const onSubmit = (values: IChangeEmail) => {
    setEdit(false);
    changeEmail({ ...values, userId: String(user!.id) })
      .then(() => {
        messageApi.open({
          type: 'success',
          content: 'E-mail обновлен',
        });
      })
      .catch(() => {
        form.setFields([{ name: 'email', errors: ['Ошибка, возможно такой e-mail уже есть'] }]);
      });
  };

  return (
    <div>
      {contextHolder}
      <Flex justify="space-between">
        <Typography.Title level={3}>Изменить e-mail</Typography.Title>
      </Flex>

      <Divider style={{ margin: '24px 0' }} />

      <Form
        name="basic"
        initialValues={{ email: user?.email }}
        onFinish={onSubmit}
        autoComplete="off"
        layout="vertical"
        form={form}
        disabled={!edit}
      >
        <Form.Item
          label="E-mail"
          name="email"
          required={false}
          rules={[
            {
              type: 'email',
              message: 'The input is not valid E-mail!',
            },
            {
              required: true,
              message: 'Please input your E-mail!',
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>

      <Divider style={{ margin: '24px 0' }} />

      <Flex justify="end" gap={24}>
        {edit ? (
          <>
            <Button
              type="primary"
              danger
              onClick={() => {
                form.resetFields();
                setEdit(false);
              }}
            >
              Отменить
            </Button>
            <Button type="primary" icon={<SaveOutlined />} disabled={!edit} onClick={() => form.submit()}>
              Сохранить изменения
            </Button>
          </>
        ) : (
          <Button onClick={onToggle} icon={<EditOutlined />} htmlType="button">
            Редактировать
          </Button>
        )}
      </Flex>
    </div>
  );
};

export default ChangeEmail;
