import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import ruRU from 'antd/locale/ru_RU';
import ru from 'antd/es/date-picker/locale/ru_RU';
import './styles/index.less';

import { router } from './views/routes';

import buddhistEra from 'dayjs/plugin/buddhistEra';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';

dayjs.extend(buddhistEra);

const buddhistLocale: typeof ru = {
  ...ru,
  lang: {
    ...ru.lang,
  },
};

// ConfigProvider level locale
const globalBuddhistLocale: typeof ruRU = {
  ...ruRU,
  DatePicker: {
    ...ruRU.DatePicker!,
    lang: buddhistLocale.lang,
  },
};

const App = () => (
  <ConfigProvider
    locale={globalBuddhistLocale}
    theme={{
      components: {
        Input: {
          paddingBlock: 8,
        },
        Tooltip: {
          colorBgSpotlight: '#EFEFEF',
          colorText: '#7D7D7D',
          colorTextLightSolid: '#7D7D7D',
          boxShadowSecondary: '0 2px 6px 1px rgba(0, 0, 0, 0.05)',
        },
        Button: {
          contentFontSizeLG: 18,
          contentLineHeightLG: 1.6,
          controlHeightLG: 48,
          contentLineHeight: 1.6,
          controlHeight: 40,
          primaryShadow: 'none',
        },
        Typography: {
          titleMarginTop: 0,
        },
        DatePicker: {
          cellActiveWithRangeBg: '#000',
        },
        Slider: {
          handleLineWidth: 1,
          handleLineWidthHover: 2,
          handleSize: 18,
          handleSizeHover: 20,
          // handleActiveColor: '#bfbfbf',
          // handleColor: '#bfbfbf',
          railBg: '#EFEFEF',
          railSize: 6,
          trackBg: '#38A169',
          controlSize: 16,
          dotActiveBorderColor: '#38A169',
          dotSize: 10,
        },
      },
      token: {
        colorLink: '#38A169',
        colorPrimary: '#38A169',
        fontFamily: 'Inter',
      },
    }}
  >
    <RouterProvider router={router} />
  </ConfigProvider>
);

export default App;
