import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Grid, Menu, theme } from 'antd';
import { ArrowLeftOutlined, MailOutlined, SecurityScanOutlined, UserOutlined } from '@ant-design/icons';

import Account from './Account';
import ChangePassword from './ChangePassword';

import styles from './styles.module.less';
import classNames from 'classnames';
import ChangeEmail from './ChangeEmail';

const { useBreakpoint } = Grid;

const Profile = () => {
  const { md } = useBreakpoint();
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const [tabId, setTabId] = useState('account');

  const items = [
    {
      key: 'account',
      label: 'Персональные данные',
      icon: <UserOutlined />,
    },
    {
      key: 'changeEmail',
      label: 'Изменить e-mail',
      icon: <MailOutlined />,
    },
    {
      key: 'changePassword',
      label: 'Изменить пароль',
      icon: <SecurityScanOutlined />,
    },
  ];

  return (
    <div className={classNames(styles.grid, { _mobile: !md })}>
      <div className={classNames(styles.menu, { _mobile: !md })}>
        <Menu
          activeKey={tabId}
          defaultSelectedKeys={['account']}
          style={{
            padding: '12px 8px',
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
            marginBottom: '12px',
          }}
          items={items}
          onClick={({ key }) => {
            setTabId(key);
          }}
        />
        <Button type="link" icon={<ArrowLeftOutlined />} block={md} size="large">
          <Link to="/">Вернутся назад</Link>
        </Button>
      </div>

      <div
        style={{
          padding: '24px 24px 48px',
          marginBottom: '24px',
          background: colorBgContainer,
          borderRadius: borderRadiusLG,
        }}
      >
        {tabId === 'account' && <Account />}
        {tabId === 'changeEmail' && <ChangeEmail />}
        {tabId === 'changePassword' && <ChangePassword />}
      </div>
    </div>
  );
};

export default Profile;
