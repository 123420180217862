import axiosInstance from '@services/axios';
import { TPollsListResponse } from './interfaces';

class PollsService {
  async fetchPollsList(patientId: string | number): Promise<TPollsListResponse> {
    const response = await axiosInstance.get<TPollsListResponse>(`/polls/${patientId}/list`);

    return response.data;
  }
}

export const Polls = new PollsService();
